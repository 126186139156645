import { Component, OnInit } from '@angular/core';
import { Ticket } from 'src/app/_models/ticket';
import { BaseListComponent } from '../base-list/base-list.component';

@Component({
	selector: 'tim-ticket-list',
	templateUrl: './ticket-list.component.html',
	styles: []
})
export class TicketListComponent extends BaseListComponent<Ticket> implements OnInit {

	constructor() {
		super();
	}

	public ngOnInit(): void { }
}
