import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthState } from 'src/app/_store/reducers/auth.reducers';
import { AppState, selectAuthState, selectTrackingState, selectUserQueryState } from 'src/app/_store/app.states';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { TicketTimeTrackingState } from 'src/app/_store/reducers/ticket-time-tracking.reducers';
import { GetCurrentTicketTimeTracking } from 'src/app/_store/actions/ticket-time-tracking.actions';
import { UserQueryState } from 'src/app/_store/reducers/user-query.reducers';
import { GetUsersQueries } from 'src/app/_store/actions/user-query.actions';
import { UserInformation } from 'src/app/_models/token-user';

@Component({
	selector: 'tim-app-layout',
	templateUrl: './app-layout.component.html',
	styleUrls: []
})
export class AppLayoutComponent implements OnInit, OnDestroy {
	public state: Observable<any>;
	public authState: Observable<AuthState>;
	public trackingState: Observable<TicketTimeTrackingState>;
	public userQueryState: Observable<UserQueryState>;

	public userInformation: UserInformation;
	public subscription: Subscription;

	constructor(private store: Store<AppState>) {
		this.state = this.store.select(selectAuthState);
		this.authState = this.state.pipe(
			map(state => state as AuthState)
		);

		const tempState: Observable<any> = this.store.select(selectTrackingState);
		this.trackingState = tempState.pipe(
			map(state => state as TicketTimeTrackingState)
		);

		const tempQueryState: Observable<any> = this.store.select(selectUserQueryState);
		this.userQueryState = tempQueryState as Observable<UserQueryState>;
		this.userQueryState.pipe(
			map(state => state as UserQueryState)
		);

		this.subscription = this.authState.pipe(
			tap(x => x.userInformation && this.store.dispatch(new GetUsersQueries(x.userInformation.userId)))
		).subscribe(x => this.userInformation = x.userInformation)
	}

	public ngOnInit(): void {
		this.store.dispatch(new GetCurrentTicketTimeTracking);
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
