import { Action } from '@ngrx/store';
import { UserQuery } from 'src/app/_models/user-query';

export enum UserQueryActionTypes {
    GET_USERSQUERIES = '[UsersQueries] GetUsersQueries',
    GET_USERSQUERIES_SUCCESS = '[UsersQueries] GetUsersQueriesSuccess',
    GET_USERSQUERIES_FAILURE = '[UsersQueries] GetUsersQueriesFailure'
}

export class GetUsersQueries implements Action {
    readonly type = UserQueryActionTypes.GET_USERSQUERIES;
    constructor(public userId: number) { }
}

export class GetUsersQueriesSuccess implements Action {
    readonly type = UserQueryActionTypes.GET_USERSQUERIES_SUCCESS;
    constructor(public usersQueries: UserQuery[]) { }
}

export class GetUsersQueriesFailure implements Action {
    readonly type = UserQueryActionTypes.GET_USERSQUERIES_FAILURE;
    constructor(public payload: any) { }
}

export type All =
    | GetUsersQueries
    | GetUsersQueriesSuccess
    | GetUsersQueriesFailure;
