import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { AppState } from '../_store/app.states';
// import { NotificationType } from 'angular2-notifications';
import { LogInFailure } from '../_store/actions/auth.actions';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private authService: AuthService;
    constructor(private injector: Injector) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authService = this.injector.get(AuthService);
        const token: string = this.authService.getToken();
        request = request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`,
                'X-Auth-Token': `${token}`,
                'Content-Type': 'application/json'
            }
        });
        return next.handle(request);
    }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private store: Store<AppState>) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            catchError((response: any) => {
                if (response instanceof HttpErrorResponse && response.status === 401) {
                    // localStorage.removeItem('token');
                    // localStorage.removeItem('user');
                    this.store.dispatch(new LogInFailure({ error: { error: { userMessage: 'Bitte erneut einloggen' } } }));
                    this.router.navigateByUrl('/auth/login');
                }

                // console.log('resp', response.error.userMessage);
                // this.store.dispatch(new ShowAlert({ type: NotificationType.Alert, title: 'Warnung', message: response.error.userMessage, identifier: moment().unix() }));

                return throwError(response);
                // return Observable.throw(response);
            })
        );
    }
}