import { BaseService } from "./base.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { TicketTimeTracking } from "../_models/ticket-time-tracking";
import * as moment from 'moment';
import { PagingModel } from '../_models/paging-model';


@Injectable()
export class TicketTimeTrackingService extends BaseService {
    constructor(private httpClient: HttpClient) {
        super('ticketstimetrackings');
    }

    public getByTicketId(ticketId: number): Observable<TicketTimeTracking[]> {
        const params = ['TicketId', ticketId];
        const url = this.getEndpoint('', params);
        return this.httpClient.get<TicketTimeTracking[]>(url);
    }

    public getByTaskId(taskId: number): Observable<TicketTimeTracking[]> {
        const params = ['TaskId', taskId];
        const url = this.getEndpoint('', params);
        return this.httpClient.get<TicketTimeTracking[]>(url);
    }

    public getById(ticketTimeTrackingId: number): Observable<TicketTimeTracking> {
        const url = this.getEndpoint(`${ticketTimeTrackingId}`);
        return this.httpClient.get<TicketTimeTracking>(url);
    }

    public getCurrent(): Observable<TicketTimeTracking> {
        const url = this.getEndpoint('current');
        return this.httpClient.get<TicketTimeTracking>(url);
    }

    public add(ticketTimeTracking: TicketTimeTracking): Observable<TicketTimeTracking> {
        const url = this.getEndpoint('');
        return this.httpClient.post<TicketTimeTracking>(url, JSON.stringify(ticketTimeTracking));
    }

    public update(ticketTimeTracking: TicketTimeTracking): Observable<TicketTimeTracking> {
        const url = this.getEndpoint(`${ticketTimeTracking.TicketsTimeTrackingId}`);
        return this.httpClient.put<TicketTimeTracking>(url, JSON.stringify(ticketTimeTracking));
    }

    public delete(ticketTimeTracking: TicketTimeTracking): Observable<TicketTimeTracking> {
        const url = this.getEndpoint(`${ticketTimeTracking.TicketsTimeTrackingId}`);
        return this.httpClient.delete<TicketTimeTracking>(url);
    }

    public startTracking(ticketId: number, chargeTypeId: number, taskId?: number): Observable<TicketTimeTracking> {
        const newTracking = new TicketTimeTracking();
        newTracking.TicketId = ticketId;
        newTracking.TicketTaskId = taskId;
        newTracking.ChargeTypeId = chargeTypeId;
        newTracking.TrackingStart = moment().startOf('minute').toDate();

        const url = this.getEndpoint('current/start');
        return this.httpClient.post<TicketTimeTracking>(url, JSON.stringify(newTracking));
    }

    public stopTracking(ticketTimeTracking: TicketTimeTracking): Observable<TicketTimeTracking> {
        const url = this.getEndpoint(`${ticketTimeTracking.TicketsTimeTrackingId}`);
        ticketTimeTracking.TrackingEnd = moment().startOf('minute').toDate();

        return this.httpClient.put<TicketTimeTracking>(url, JSON.stringify(ticketTimeTracking));
    }

    public getByDate(startDate: Date, endDate?: Date): Observable<TicketTimeTracking[]> {
        const startUnix = moment(startDate).unix();
        const parameters = { start: startUnix, end: null };

        if (endDate) {
            const endUnix = moment(endDate).unix();
            parameters.end = endUnix;
        }

        const url = this.getParamsEndpoint('current/bydate', parameters);
        return this.httpClient.get<TicketTimeTracking[]>(url);
    }
}