import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/_services/alert.service';
import { Observable } from 'rxjs';
import { Alert } from 'src/app/_models/alert';

@Component({
	selector: 'tim-alert',
	templateUrl: './alert.component.html',
	styles: []
})
export class AlertComponent implements OnInit {
	public alerts: Observable<Alert[]>;

	constructor(private alertService: AlertService) { }

	public ngOnInit(): void {
		this.alerts = this.alertService.alerts;
	}

	public getAlertClass(alert: Alert): string {
		return `alert alert-${alert.type || 'info'}`;
	}

	public closeAlert(alert: Alert): void {
		this.alertService.RemoveAlert(alert);
	}
}
