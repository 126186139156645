import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomerService } from 'src/app/_services/customer.service';
import { Observable } from 'rxjs/Observable';
import { Customer } from 'src/app/_models/customer';

const CUSTOM_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CustomerSearchFormControlComponent),
	multi: true,
};

@Component({
	selector: 'tim-customer-search-form-control',
	providers: [CUSTOM_VALUE_ACCESSOR],
	templateUrl: './customer-search-form-control.component.html',
	styles: [
		'a.actionlink: {color: #49afd9;text-decoration: underline;cursor:pointer;}'
	]
})
export class CustomerSearchFormControlComponent implements OnInit, ControlValueAccessor {
	public displayText: string;
	public showSearch: boolean;
	public fallbackDisplayText: string = 'Suchen ...';
	public customer: Observable<Customer>;

	private disabled: boolean;
	private onChange: Function;
	private onTouched: Function;

	private customerId: number;

	constructor(private customerService: CustomerService) {
		this.onChange = (_: any) => { };
		this.onTouched = () => { };
		this.disabled = false;
	}

	public ngOnInit(): void {
		this.displayText = this.fallbackDisplayText;
	}

	public handleSearchCompleted(customerId: number | null) {
		this.showSearch = false;
		if (customerId) {
			this.customerId = customerId;
			this.displayText = customerId.toString();
			this.customer = this.customerService.getCustomer(customerId);
		}
		this.onChange(customerId);
		this.onTouched();
	}

	public handleShowSearch(): void {
		this.showSearch = true;
	}


	public writeValue(obj: number): void {
		this.customerId = obj;
		if (obj) {
			this.customer = this.customerService.getCustomer(this.customerId);
		}
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
