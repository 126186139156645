import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectAuthState } from './_store/app.states';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthState } from './_store/reducers/auth.reducers';
import { CheckLogIn } from './_store/actions/auth.actions';
import { Router } from '@angular/router';
import * as moment from 'moment';


@Component({
	selector: 'tim-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	private state: Observable<any>;
	private authState: Observable<AuthState>;

	constructor(
		private store: Store<AppState>,
		private router: Router
	) {
		this.state = this.store.select(selectAuthState);
		this.authState = this.state.pipe(map(state => state as AuthState));
	}

	public ngOnInit(): void {
		this.store.dispatch(new CheckLogIn());
		moment.locale('de');
	}

	public selection: any;
	public title: string = 'TimeIsMoney';
}
