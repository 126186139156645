import { Component, OnInit, forwardRef, ViewChild, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgForm } from '@angular/forms';
import { TicketStatus } from 'src/app/_models/ticket-status';
import { Observable, Subscription } from 'rxjs';
import { TicketStatusService } from 'src/app/_services/ticket-status.service';

const CUSTOM_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => TicketStatusSelectorComponent),
	multi: true,
};

@Component({
	selector: 'tim-ticket-status-selector',
	templateUrl: './ticket-status-selector.component.html',
	providers: [CUSTOM_VALUE_ACCESSOR],
	styles: []
})
export class TicketStatusSelectorComponent implements OnInit, ControlValueAccessor {
	public ticketStatuses: Observable<TicketStatus[]>;
	public ticketStatusId: number;

	private formSubscription: Subscription;

	private disabled: boolean;
	private onChange: Function;
	private onTouched: Function;

	@ViewChild('form', {static: true})
	private ngForm: NgForm;

	@Input()
	public name: string;

	@Input()
	public title: string;

	constructor(
		private ticketStatusService: TicketStatusService
	) {
		this.onChange = (_: any) => { };
		this.onTouched = () => { };
		this.disabled = false;
	}

	public ngOnInit(): void {
		this.ticketStatuses = this.ticketStatusService.getStatuses();
		this.formSubscription = this.ngForm.form.valueChanges.subscribe(x => {
			const ticketStatusId = x[this.name] as number;
			this.onChange(ticketStatusId);
			this.onTouched();
		});
	}

	public ngOnDestroy(): void {
		this.formSubscription.unsubscribe();
	}

	public writeValue(obj: number): void {
		this.ticketStatusId = obj;
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
