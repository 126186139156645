import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserQuery } from '../_models/user-query';
import { Observable } from 'rxjs';


@Injectable()
export class UserQueryService extends BaseService {

    constructor(private http: HttpClient) {
        super('usersqueries');
    }

    public getByUserId(userId: number): Observable<UserQuery[]> {
        const params = ['UserId', userId];
        const url = this.getEndpoint('', params);
        return this.http.get<UserQuery[]>(url);
    }
}