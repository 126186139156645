import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { PagingModel } from 'src/app/_models/paging-model';
import { ClrDatagridStateInterface } from '@clr/angular';

@Component({
	selector: 'tim-base-list',
	templateUrl: './base-list.component.html',
	styles: []
})
export class BaseListComponent<T> implements OnInit, OnChanges {

	@Input()
	public hiddenColumns: string[] = [];

	@Input()
	public displayColumns: string[] = [];

	@Input()
	public data: PagingModel<T>;

	@Input()
	public loading: boolean;

	@Output()
	public onPageChanged: EventEmitter<number> = new EventEmitter<number>();

	public dataPaged: PagingModel<T> = new PagingModel<T>([]);
	public pageSize: number = -1;
	public totalRows: number;
	public pageNumber: number;

	constructor() { }

	public ngOnInit(): void { }

	public ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
		const tasksChange = changes['data'];
		if (tasksChange && tasksChange.currentValue) {
			this.dataPaged = tasksChange.currentValue;
			this.pageSize = this.dataPaged.pageSize;
			this.pageNumber = this.dataPaged.pageNumber;
			this.totalRows = this.dataPaged.totalRows;
		}
	}

	protected displayColumn(name: string): boolean {
		if (!this.hiddenColumns.length && !this.displayColumns.length) {
			return true;
		}

		const hidden = this.hiddenColumns.findIndex(x => x.toLowerCase() === name.toLowerCase());
		if (hidden !== -1) {
			return false;
		}

		if (!this.displayColumns.length) {
			return true;
		}

		const display = this.displayColumns.findIndex(x => x.toLowerCase() === name.toLowerCase());
		return display !== -1;
	}

	public handleRefresh(data: ClrDatagridStateInterface): void {
		if (!data || !data.page) {
			return;
		}

		if (data.page.from > -1 && data.page.size > 0 && data.page.to > -1) {
			const currentPage = (data.page.from + data.page.size) / data.page.size;
			this.onPageChanged.next(currentPage);
		}
	}
}
