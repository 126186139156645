import { Action } from '@ngrx/store';
import { TokenUser } from '../../_models/token-user';


export enum AuthActionTypes {
	LOGIN = '[Auth] Login',
	LOGIN_SUCCESS = '[Auth] Login Success',
	LOGIN_FAILURE = '[Auth] Login Failure',
	SIGNUP = '[Auth] Signup',
	SIGNUP_SUCCESS = '[Auth] Signup Success',
	SIGNUP_FAILURE = '[Auth] Signup Failure',
	LOGOUT = '[Auth] Logout',
	GET_STATUS = '[Auth] GetStatus',

	CHECK_LOGIN = '[Auth] Check Login'
}

export class CheckLogIn implements Action {
	readonly type = AuthActionTypes.CHECK_LOGIN;
}

export class LogIn implements Action {
	readonly type = AuthActionTypes.LOGIN;
	constructor(
		public userName: string,
		public password: string,
		public clientName: string,
		public rememberMe: boolean
	) { }
}

export class LogInSuccess implements Action {
	readonly type = AuthActionTypes.LOGIN_SUCCESS;
	constructor(
		public token: string,
		public email: string,
		public tokenUser: TokenUser,
		public redirectToRoot: boolean = true) { }
}

export class LogInFailure implements Action {
	readonly type = AuthActionTypes.LOGIN_FAILURE;
	constructor(public payload: any) { }
}

export class SignUp implements Action {
	readonly type = AuthActionTypes.SIGNUP;
	constructor(public payload: any) { }
}

export class SignUpSuccess implements Action {
	readonly type = AuthActionTypes.SIGNUP_SUCCESS;
	constructor(public payload: any) { }
}

export class SignUpFailure implements Action {
	readonly type = AuthActionTypes.SIGNUP_FAILURE;
	constructor(public payload: any) { }
}

export class LogOut implements Action {
	readonly type = AuthActionTypes.LOGOUT;
}

export class GetStatus implements Action {
	readonly type = AuthActionTypes.GET_STATUS;
}

export type All =
	| LogIn
	| LogInSuccess
	| LogInFailure
	| SignUp
	| SignUpSuccess
	| SignUpFailure
	| LogOut
	| GetStatus;