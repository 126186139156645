import { TicketTimeTracking } from "../../_models/ticket-time-tracking";
import { All, TicketTimeTrackingActionTypes } from "../actions/ticket-time-tracking.actions";

export interface TicketTimeTrackingState {
    currentTracking: TicketTimeTracking;
    currentTrackingLoading: boolean;
}

export const initialState: TicketTimeTrackingState = {
    currentTracking: null,
    currentTrackingLoading: false
};

export function reducer(state = initialState, action: All) {
    switch (action.type) {
        case TicketTimeTrackingActionTypes.STOP_TICKETTIMETRACKING:
        case TicketTimeTrackingActionTypes.GET_CURRENTTICKETTIMETRACKING: {
            return {
                ...state,
                currentTrackingLoading: true
            };
        }
        case TicketTimeTrackingActionTypes.GET_CURRENTTICKETTIMETRACKING_SUCCESS: {
            return {
                ...state,
                currentTracking: action.timeTracking,
                currentTrackingLoading: false
            };
        }
        case TicketTimeTrackingActionTypes.STOP_TICKETTIMETRACKING_FAILURE:
        case TicketTimeTrackingActionTypes.GET_CURRENTTICKETTIMETRACKING_FAILURE: {
            return {
                ...state,
                currentTrackingLoading: false
            };
        }
        case TicketTimeTrackingActionTypes.START_TICKETTIMETRACKING: {
            return {
                ...state,
                currentTrackingLoading: true
            };
        }
        case TicketTimeTrackingActionTypes.START_TICKETTIMETRACKING_SUCCESS: {
            return {
                ...state,
                currentTrackingLoading: false,
                currentTracking: action.timeTracking
            };
        }
        case TicketTimeTrackingActionTypes.START_TICKETTIMETRACKING_FAILURE: {
            return {
                ...state,
                currentTrackingLoading: false
            };
        }
        case TicketTimeTrackingActionTypes.STOP_TICKETTIMETRACKING_SUCCESS: {
            return {
                ...state,
                currentTracking: null,
                currentTrackingLoading: false
            };
        }
        default: {
            return state;
        }
    }
}