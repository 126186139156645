import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'tim-loading',
	templateUrl: './loading.component.html',
	styles: []
})
export class LoadingComponent implements OnInit {

	@Input()
	public loading: boolean;

	constructor() { }

	public ngOnInit(): void {}
}
