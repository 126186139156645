import { TicketTimeTracking } from 'src/app/_models/ticket-time-tracking';
import { Action } from '@ngrx/store';

export enum TicketTimeTrackingActionTypes {
    START_TICKETTIMETRACKING = '[TicketTimeTracking] StartTicketTimeTracking',
    START_TICKETTIMETRACKING_SUCCESS = '[TicketTimeTracking] StartTicketTimeTrackingSuccess',
    START_TICKETTIMETRACKING_FAILURE = '[TicketTimeTracking] StartTicketTimeTrackingFailure',

    STOP_TICKETTIMETRACKING = '[TicketTimeTracking] StopTicketTimeTracking',
    STOP_TICKETTIMETRACKING_SUCCESS = '[TicketTimeTracking] StopTicketTimeTrackingSuccess',
    STOP_TICKETTIMETRACKING_FAILURE = '[TicketTimeTracking] StopTicketTimeTrackingFailure',

    GET_CURRENTTICKETTIMETRACKING = '[CurrentTicketTimeTracking] GetCurrentTicketTimeTracking',
    GET_CURRENTTICKETTIMETRACKING_SUCCESS = '[CurrentTicketTimeTracking] GetCurrentTicketTimeTrackingSuccess',
    GET_CURRENTTICKETTIMETRACKING_FAILURE = '[CurrentTicketTimeTracking] GetCurrentTicketTimeTrackingFailure'
}

export class StartTicketTimeTracking implements Action {
    readonly type = TicketTimeTrackingActionTypes.START_TICKETTIMETRACKING;
    constructor(
        public ticketId: number,
        public chargeTypeId: number,
        public taskId?: number) { }
}

export class StartTicketTimeTrackingSuccess implements Action {
    readonly type = TicketTimeTrackingActionTypes.START_TICKETTIMETRACKING_SUCCESS;
    constructor(public timeTracking: TicketTimeTracking) { }
}

export class StartTicketTimeTrackingFailure implements Action {
    readonly type = TicketTimeTrackingActionTypes.START_TICKETTIMETRACKING_FAILURE;
    constructor(public payload: any) { }
}




export class StopTicketTimeTracking implements Action {
    readonly type = TicketTimeTrackingActionTypes.STOP_TICKETTIMETRACKING;
    constructor(public timeTracking: TicketTimeTracking) { }
}

export class StopTicketTimeTrackingSuccess implements Action {
    readonly type = TicketTimeTrackingActionTypes.STOP_TICKETTIMETRACKING_SUCCESS;
    constructor(public timeTracking: TicketTimeTracking) { }
}

export class StopTicketTimeTrackingFailure implements Action {
    readonly type = TicketTimeTrackingActionTypes.STOP_TICKETTIMETRACKING_FAILURE;
    constructor(public payload: any) { }
}



export class GetCurrentTicketTimeTracking implements Action {
    readonly type = TicketTimeTrackingActionTypes.GET_CURRENTTICKETTIMETRACKING;
    constructor() { }
}

export class GetCurrentTicketTimeTrackingSuccess implements Action {
    readonly type = TicketTimeTrackingActionTypes.GET_CURRENTTICKETTIMETRACKING_SUCCESS;
    constructor(public timeTracking: TicketTimeTracking) { }
}

export class GetCurrentTicketTimeTrackingFailure implements Action {
    readonly type = TicketTimeTrackingActionTypes.GET_CURRENTTICKETTIMETRACKING_FAILURE;
    constructor(public payload: any) { }
}
export type All =
    | GetCurrentTicketTimeTracking
    | GetCurrentTicketTimeTrackingSuccess
    | GetCurrentTicketTimeTrackingFailure
    | StartTicketTimeTracking
    | StartTicketTimeTrackingSuccess
    | StartTicketTimeTrackingFailure
    | StopTicketTimeTracking
    | StopTicketTimeTrackingSuccess
    | StopTicketTimeTrackingFailure;
