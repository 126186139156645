import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Customer } from '../_models/customer';
import { PagingModel } from '../_models/paging-model';
import { Observable } from 'rxjs';

@Injectable()
export class CustomerService extends BaseService {

    constructor(private http: HttpClient) {
        super('customers');
    }

    public getCustomers(pageNumber: number): Observable<PagingModel<Customer>> {
        const params = ['page', pageNumber];
        const url = this.getEndpoint('paged', params);
        return this.http.get<PagingModel<Customer>>(url)
    }

    public addCustomer(customer: Customer): Observable<Customer> {
        const url = this.getEndpoint('');
        return this.http.post<Customer>(url, JSON.stringify(customer));
    }

    public getCustomer(customerId: number): Observable<Customer> {
        const url = this.getEndpoint(`${customerId}`);
        return this.http.get<Customer>(url);
    }

    public updateCustomer(customer: Customer): Observable<Customer> {
        const url = this.getEndpoint(`${customer.CustomerId}`);
        return this.http.put<Customer>(url, JSON.stringify(customer));
    }

    public deleteCustomer(customer: Customer): Observable<Customer> {
        const url = this.getEndpoint(`${customer.CustomerId}`);
        return this.http.delete<Customer>(url);
    }

    public searchCustomer(searchText: string): Observable<Customer[]> {
        const url = this.getEndpoint(`search/?q=${searchText}`);
        return this.http.get<Customer[]>(url);
    }
}
