import { Component, OnInit, forwardRef, ViewChild, OnDestroy, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

const CUSTOM_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DatePickerComponent),
	multi: true,
};

@Component({
	selector: 'tim-date-picker',
	providers: [CUSTOM_VALUE_ACCESSOR],
	templateUrl: './date-picker.component.html',
	styles: []
})
export class DatePickerComponent implements OnInit, OnDestroy {
	private disabled: boolean;
	private onChange: Function;
	private onTouched: Function;
	private formChangesSubscription: Subscription;

	public date: Date;
	public selectedDate: string | null;

	@ViewChild('form', { static: true })
	public ngForm: NgForm;

	@Input()
	public name: string;

	@Input()
	public label: string;

	constructor() {
		this.onChange = (_: any) => { };
		this.onTouched = () => { };
		this.disabled = false;
	}

	public ngOnInit(): void {
		this.formChangesSubscription = this.ngForm.form.valueChanges.subscribe(x => {
			const dateString = moment(x[this.name], 'DD.MM.YYYY').toISOString();

			this.onChange(dateString);
			this.onTouched();
		});
	}

	public ngOnDestroy(): void {
		this.formChangesSubscription.unsubscribe();
	}

	public writeValue(obj: string | null): void {
		if (obj) {
			const date = moment(obj, moment.ISO_8601).format('DD.MM.YYYY');

			this.selectedDate = date;
			this.date = new Date(date);
		}
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
