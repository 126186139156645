export class TokenUser {
    success?: boolean;
    token?: string;
    user?: UserInformation;
}

export class UserInformation {
    email?: string;
    firstName?: string;
    lastName?: string;
    lastLogin?: Date;
    userId?: number;
    userName?: string;
}

export class LoginUser {
	id?: string;
	email?: string;
	password?: string;
	rememberMe?: boolean;
	token?: string;
}
