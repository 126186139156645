import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseFormContainerComponent } from '../helpers/base-form-container/base-form-container.component';
import { UnsavedChangesService } from './unsaved-changes.service';

@Injectable()
export class FormsDeactivateService implements CanDeactivate<BaseFormContainerComponent> {

	constructor(private unsavedChangesService: UnsavedChangesService) { }

	public canDeactivate(component: BaseFormContainerComponent,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		if (!component.isDirty) {
			return true;
		}

		const z = this.unsavedChangesService.addDynamicComponent();
		return z.then(x => true)
			.catch(x => false);
	}
}
