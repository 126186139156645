import { Component, OnInit, Input, Output, EventEmitter, ContentChild, OnDestroy } from '@angular/core';
import { ConfirmDialogTriggerDirective } from './confirm-dialog-trigger.directive';
import { Subscription } from 'rxjs';

@Component({
	selector: 'tim-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styles: []
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
	@Input()
	public question: string;

	@Output()
	public onConfirm: EventEmitter<any> = new EventEmitter<any>();

	@Output()
	public onCancel: EventEmitter<any> = new EventEmitter<any>();

	@ContentChild(ConfirmDialogTriggerDirective, { static: true })
	public trigger: ConfirmDialogTriggerDirective;

	public show: boolean;

	private subscription: Subscription;

	constructor() { }

	public ngOnInit(): void {
		this.subscription = this.trigger.onClick.subscribe(() => this.show = true);
	}

	public ngOnDestroy(): void {
		this.subscription && this.subscription.unsubscribe();
	}

	public handleCancel(): void {
		this.show = false;
		this.onCancel.next();
	}

	public handleOkay(): void {
		this.show = false;
		this.onConfirm.next();
	}
}
