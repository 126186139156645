import { Component, OnInit, forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/_models/user';
import { UserService } from 'src/app/_services/user.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => UserSearchFormControlComponent),
	multi: true,
};

@Component({
	selector: 'tim-user-search-form-control',
	providers: [CUSTOM_VALUE_ACCESSOR],
	templateUrl: './user-search-form-control.component.html',
	styles: []
})
export class UserSearchFormControlComponent implements OnInit {
	public displayText: string;
	public showSearch: boolean;
	public fallbackDisplayText: string = 'Suchen ...';
	public user: Observable<User>;

	private disabled: boolean;
	private onChange: Function;
	private onTouched: Function;

	private userId: number;

	constructor(private userService: UserService) {
		this.onChange = (_: any) => { };
		this.onTouched = () => { };
		this.disabled = false;
	}

	public ngOnInit(): void {
		this.displayText = this.fallbackDisplayText;
	}

	public handleSearchCompleted(userId: number | null) {
		this.showSearch = false;
		if (userId) {
			this.userId = userId;
			this.displayText = userId.toString();
			this.user = this.userService.getById(userId);
		}
		this.onChange(userId);
		this.onTouched();
	}

	public handleShowSearch(): void {
		this.showSearch = true;
	}


	public writeValue(obj: number): void {
		this.userId = obj;
		if (this.userId) {
			this.user = this.userService.getById(this.userId);
		}
	}

	public getUserName(user: User): string {
		if(!user) {
			return this.fallbackDisplayText;
		}

		return `${user.FirstName } ${user.LastName}`;
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
