
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of, pipe } from 'rxjs';


import {
	AuthActionTypes,
	LogIn, LogInSuccess, LogInFailure,
	SignUp, SignUpSuccess, SignUpFailure,
	CheckLogIn,
} from '../actions/auth.actions';
import { AlertService } from '../../_services/alert.service';
import { AuthService } from '../../_services/auth.service';
import { AuthResponse, AuthResponseUser } from '../../_models/auth-response';
import { Store } from '@ngrx/store';
import { AppState } from '../app.states';
import { GetUsersQueries } from '../actions/user-query.actions';


@Injectable()
export class AuthEffects {

	constructor(
		private store: Store<AppState>,
		private actions: Actions,
		private authService: AuthService,
		private alertService: AlertService,
		private router: Router,
	) { }

	@Effect()
	LogIn: Observable<any> = this.actions.pipe(
		ofType(AuthActionTypes.LOGIN),
		map((action: LogIn) => action),
		switchMap(payload => {
			return this.authService.logIn(payload.userName, payload.clientName, payload.password, payload.rememberMe).pipe(
				map((user) => new LogInSuccess(user.token, user.user.email, user)),
				catchError((error) => of(new LogInFailure({ error: error }))));
		})
	);

	@Effect()
	CheckLogIn: Observable<any> = this.actions.pipe(
		ofType(AuthActionTypes.CHECK_LOGIN),
		map((action: CheckLogIn) => action.type),
		switchMap(() => {
			return this.authService.checkLogin().pipe(
				map(user => {
					if (user.success && user.success === true) {
						const authResponse = new AuthResponse();
						const authResponseUser = new AuthResponseUser();
						authResponse.success = true;
						authResponse.token = user.token;
						authResponseUser.email = user.user.email;
						authResponseUser.firstName = user.user.firstName;
						authResponseUser.lastName = user.user.lastName;
						authResponseUser.userId = user.user.userId;
						authResponseUser.userName = user.user.userName;

						authResponse.user = authResponseUser;

						return new LogInSuccess(user.token, user.user.email, user, false);
					}
					return new LogInFailure({ error: { error: { userMessage: 'Bitte erneut einloggen' } } });
				})
			)
		})
	);


	@Effect({ dispatch: false })
	LogInSuccess: Observable<LogInSuccess> = this.actions.pipe(
		ofType(AuthActionTypes.LOGIN_SUCCESS),
		pipe(
			map(user => user as LogInSuccess),
			tap((user) => {
				user
				const authResponse = new AuthResponse();
				const authResponseUser = new AuthResponseUser();
				authResponse.success = true;
				authResponse.token = user.token;
				authResponseUser.email = user.email;
				authResponseUser.firstName = user.tokenUser.user.firstName;
				authResponseUser.lastName = user.tokenUser.user.lastName;
				authResponseUser.userId = user.tokenUser.user.userId;
				authResponseUser.userName = user.tokenUser.user.userName;

				authResponse.user = authResponseUser;
				localStorage.setItem('token', user.token);
				localStorage.setItem('user', JSON.stringify(user.tokenUser));

				this.store.dispatch(new GetUsersQueries(user.tokenUser.user.userId));
				if (user.redirectToRoot) {
					this.router.navigateByUrl('/');
				}
			})
		)
	);

	@Effect({ dispatch: false })
	LogInFailure: Observable<any> = this.actions.pipe(
		ofType(AuthActionTypes.LOGIN_FAILURE),
		tap(() => {
			this.alertService.Error('Login-Daten inkorrekt');
			localStorage.removeItem('token');
			localStorage.removeItem('user');
		})
	);

	@Effect()
	SignUp: Observable<any> = this.actions.pipe(
		ofType(AuthActionTypes.SIGNUP),
		map((action: SignUp) => action.payload),
		switchMap(payload => {
			return this.authService.signUp(payload.email, payload.password).pipe(
				map((user) => {
					return new SignUpSuccess({ token: user.token, email: payload.email });
				}),
				catchError((error) => {
					console.log('error', error);
					if (error instanceof Object) {
						return of(new SignUpFailure({ error: error.userMessage || error }));
					} else {
						return of(new SignUpFailure({ error: error }));
					}
				}));
		}));

	@Effect({ dispatch: false })
	SignUpSuccess: Observable<any> = this.actions.pipe(
		ofType(AuthActionTypes.SIGNUP_SUCCESS),
		tap((user) => {
			localStorage.setItem('token', user.payload.token);
			this.router.navigateByUrl('/');
		})
	);

	@Effect({ dispatch: false })
	SignUpFailure: Observable<any> = this.actions.pipe(
		ofType(AuthActionTypes.SIGNUP_FAILURE),
		tap(error => this.alertService.Warn(null, error.userMessage))
	);

	@Effect({ dispatch: false })
	public LogOut: Observable<any> = this.actions.pipe(
		ofType(AuthActionTypes.LOGOUT),
		tap(() => {
			console.log('logout');
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			this.router.navigateByUrl('/login');
		})
	);

	@Effect({ dispatch: false })
	GetStatus: Observable<any> = this.actions.pipe(
		ofType(AuthActionTypes.GET_STATUS),
		switchMap(() => {
			return this.authService.getStatus();
		}));

}
