import { TicketTask } from '../_models/ticket-task';
import { Ticket } from '../_models/ticket';
import { TicketTimeTracking } from '../_models/ticket-time-tracking';

export class TrackingTaskItem {
	constructor() {
		this.trackings = [];
	}

	public totalTime: number;
	public task: TicketTask;
	public ticket: Ticket;
	public trackings: TicketTimeTracking[];
}

export class TrackingViewModel {
	constructor() {
		this.tasks = [];
	}
	public tasks: TrackingTaskItem[];
}