import { BaseService } from "./base.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { TicketTask } from "../_models/ticket-task";
import { PagingModel } from '../_models/paging-model';
import * as moment from 'moment';


@Injectable()
export class TicketTaskService extends BaseService {
    constructor(private httpClient: HttpClient) {
        super('ticketstasks');
    }

    public getByAssigneePaged(userId: number, pageId: number): Observable<PagingModel<TicketTask>> {
        const url = this.getParamsEndpoint('paged', { AssignedToId: userId, page: pageId });
        return this.httpClient.get<PagingModel<TicketTask>>(url);
    }

    public getByTicketId(ticketId: number): Observable<TicketTask[]> {
        const params = ['TicketId', ticketId];
        const url = this.getEndpoint('', params);
        return this.httpClient.get<TicketTask[]>(url);
    }

    public getByTicketIdPaged(ticketId: number, pageNumber): Observable<PagingModel<TicketTask>> {
        const params = { TicketId: ticketId, page: pageNumber };
        const url = this.getParamsEndpoint('paged', params);
        return this.httpClient.get<PagingModel<TicketTask>>(url);
    }

    public getById(taskId: number): Observable<TicketTask> {
        const url = this.getEndpoint(`${taskId}`);
        return this.httpClient.get<TicketTask>(url);
    }

    public getTicketsByCustomerIdPaged(customerId: number, pageNumber: number): Observable<PagingModel<TicketTask>> {
        const params = { CustomerId: customerId, page: pageNumber };
        const url = this.getParamsEndpoint('paged', params);
        return this.httpClient.get<PagingModel<TicketTask>>(url);
    }

    public getByDate(startDate: Date, endDate: Date): Observable<TicketTask[]> {
        const start = moment(startDate).unix();
        const end = moment(endDate).unix();
        const url = this.getParamsEndpoint('bydate', { start, end });
        return this.httpClient.get<TicketTask[]>(url);
    }

    public add(task: TicketTask): Observable<TicketTask> {
        const url = this.getEndpoint('');
        return this.httpClient.post<TicketTask>(url, JSON.stringify(task));
    }

    public update(task: TicketTask): Observable<TicketTask> {
        const url = this.getEndpoint(`${task.TicketTaskId}`);
        return this.httpClient.put<TicketTask>(url, JSON.stringify(task));
    }

    public delete(task: TicketTask): Observable<TicketTask> {
        const url = this.getEndpoint(`${task.TicketTaskId}`);
        return this.httpClient.delete<TicketTask>(url);
    }
}