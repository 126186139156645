import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import {
    TicketTimeTrackingActionTypes,
    GetCurrentTicketTimeTrackingSuccess,
    GetCurrentTicketTimeTrackingFailure,
    GetCurrentTicketTimeTracking,
    StartTicketTimeTrackingSuccess,
    StartTicketTimeTrackingFailure,
    StartTicketTimeTracking,
    StopTicketTimeTrackingSuccess,
    StopTicketTimeTrackingFailure,
    StopTicketTimeTracking
} from '../actions/ticket-time-tracking.actions';
import { AlertService } from '../../_services/alert.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.states';
import { TicketTimeTrackingService } from '../../_services/ticket-time-tracking.service';


@Injectable()
export class TicketTimeTrackingEffects {

    constructor(
        private store: Store<AppState>,
        private actions: Actions,
        private ticketTimeTrackingService: TicketTimeTrackingService,
        private alertService: AlertService
    ) { }

    
    @Effect()
    StartTicketTimeTracking: Observable<StartTicketTimeTrackingSuccess | StartTicketTimeTrackingFailure> = this.actions.pipe(
        ofType(TicketTimeTrackingActionTypes.START_TICKETTIMETRACKING),
        map(x => x as StartTicketTimeTracking),
        switchMap(request => {
            return this.ticketTimeTrackingService.startTracking(request.ticketId, request.chargeTypeId, request.taskId).pipe(
                map(user => new StartTicketTimeTrackingSuccess(user)),
                catchError(error => of(new StartTicketTimeTrackingFailure(error))));
        }));

    @Effect({ dispatch: false })
    StartTicketTimeTrackingSuccess: Observable<StartTicketTimeTrackingSuccess> = this.actions.pipe(
        ofType(TicketTimeTrackingActionTypes.START_TICKETTIMETRACKING_SUCCESS),
        tap(user => user)
    );

    @Effect({ dispatch: false })
    StartTicketTimeTrackingFailure: Observable<StartTicketTimeTrackingFailure> = this.actions.pipe(
        ofType(TicketTimeTrackingActionTypes.START_TICKETTIMETRACKING_FAILURE)
    );



    @Effect()
    StopTicketTimeTracking: Observable<StopTicketTimeTrackingSuccess | StopTicketTimeTrackingFailure> = this.actions.pipe(
        ofType(TicketTimeTrackingActionTypes.STOP_TICKETTIMETRACKING),
        map(x => x as StopTicketTimeTracking),
        switchMap(request => {
            request.timeTracking.TrackingEnd = new Date();
            return this.ticketTimeTrackingService.update(request.timeTracking).pipe(
                map(user => new StopTicketTimeTrackingSuccess(user)),
                catchError(error => of(new StopTicketTimeTrackingFailure(error))));
        }));

    @Effect({ dispatch: false })
    StopTicketTimeTrackingSuccess: Observable<StopTicketTimeTrackingSuccess> = this.actions.pipe(
        ofType(TicketTimeTrackingActionTypes.STOP_TICKETTIMETRACKING_SUCCESS),
        tap(user => user)
    );

    @Effect({ dispatch: false })
    StopTicketTimeTrackingFailure: Observable<StopTicketTimeTrackingFailure> = this.actions.pipe(
        ofType(TicketTimeTrackingActionTypes.STOP_TICKETTIMETRACKING_FAILURE)
    );






    @Effect()
    GetCurrentTicketTimeTracking: Observable<GetCurrentTicketTimeTrackingSuccess | GetCurrentTicketTimeTrackingFailure> = this.actions.pipe(
        ofType(TicketTimeTrackingActionTypes.GET_CURRENTTICKETTIMETRACKING),
        map(x => x as GetCurrentTicketTimeTracking),
        switchMap(() => {
            return this.ticketTimeTrackingService.getCurrent().pipe(
                map(user => new GetCurrentTicketTimeTrackingSuccess(user)),
                catchError(error => of(new GetCurrentTicketTimeTrackingFailure(error))));
        }));

    @Effect({ dispatch: false })
    GetCurrentTicketTimeTrackingSuccess: Observable<GetCurrentTicketTimeTrackingSuccess> = this.actions.pipe(
        ofType(TicketTimeTrackingActionTypes.GET_CURRENTTICKETTIMETRACKING_SUCCESS),
        tap(user => user)
    );

    @Effect({ dispatch: false })
    GetCurrentTicketTimeTrackingFailure: Observable<GetCurrentTicketTimeTrackingFailure> = this.actions.pipe(
        ofType(TicketTimeTrackingActionTypes.GET_CURRENTTICKETTIMETRACKING_FAILURE)
    );
}