import { Component, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Ticket } from 'src/app/_models/ticket';
import { Observable } from 'rxjs';
import { TicketService } from 'src/app/_services/ticket.service';

const CUSTOM_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => TicketSearchFormControlComponent),
	multi: true,
};

@Component({
	selector: 'tim-ticket-search-form-control',
	templateUrl: './ticket-search-form-control.component.html',
	providers: [CUSTOM_VALUE_ACCESSOR],
	styles: []
})
export class TicketSearchFormControlComponent implements OnInit {
	public displayText: string;
	public showSearch: boolean;
	public fallbackDisplayText: string = 'Suchen ...';
	public ticket: Observable<Ticket>;

	public disabled: boolean;
	private onChange: Function;
	private onTouched: Function;

	private ticketId: number;

	constructor(private ticketService: TicketService) {
		this.onChange = (_: any) => { };
		this.onTouched = () => { };
		this.disabled = false;
	}

	public ngOnInit(): void {
		this.displayText = this.fallbackDisplayText;
	}

	public getLabelText(ticket: Ticket): string {
		if (ticket) {
			return `${ticket.ReferenceNumber} - ${ticket.Customer.Name1} | ${ticket.Label}`;
		}

		return this.fallbackDisplayText;
	}

	public handleSearchCompleted(ticketId: number | null) {
		this.showSearch = false;
		if (ticketId) {
			this.ticketId = ticketId;
			this.displayText = ticketId.toString();
			this.ticket = this.ticketService.getTicket(ticketId);
		}
		this.onChange(ticketId);
		this.onTouched();
	}

	public handleShowSearch(): void {
		this.showSearch = true;
	}


	public writeValue(obj: number): void {
		this.ticketId = obj;
		if (obj) {
			this.ticket = this.ticketService.getTicket(this.ticketId);
		}
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
