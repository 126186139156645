import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Alert } from '../_models/alert';
import * as moment from 'moment';

@Injectable()
export class AlertService {
    public alerts: Subject<Alert[]> = new Subject<Alert[]>();
    private alertStore: Alert[] = [];

    constructor() { }

    private Alert(message: string, type: string, timeout: number = 5000): void {
        const currentAlerts = this.alertStore;
        const alert = new Alert();
        alert.identifier = moment().unix();
        alert.type = type;
        alert.message = message || 'Erfolg!';

        currentAlerts.push(alert);

        timeout && setTimeout(() => {
            this.RemoveAlert(alert);
        }, timeout);

        this.alerts.next(currentAlerts);
    }

    public Warn(message?: string, timeOut?: number): void {
        this.Alert(message, 'warning');
    }

    public Success(message?: string, timeOut?: number): void {
        this.Alert(message, 'success');
    }

    public Error(message?: string, timeOut?: number): void {
        this.Alert(message, 'danger');
    }

    public Info(message?: string, timeOut?: number): void {
        this.Alert(message, 'info');
    }

    public RemoveAlert(alert: Alert): void {
        const alerts = this.alertStore;
        const index = alerts.findIndex(x => x.identifier === alert.identifier);
        if (index === -1) {
            return;
        }

        this.alertStore.splice(index, 1);
        this.alerts.next(this.alertStore);
    }
}