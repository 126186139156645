import { AuthActionTypes, All } from '../actions/auth.actions';
import { UserInformation, LoginUser } from '../../_models/token-user';


export interface AuthState {
	// is a user authenticated?
	isAuthenticated: boolean;
	// if authenticated, there should be a user object
	user: LoginUser | null;
	// error message
	errorMessage: string | null;

	userInformation: UserInformation | null;

	isLoading: boolean;
}

export const initialState: AuthState = {
	isAuthenticated: false,
	user: null,
	errorMessage: null,
	userInformation: null,
	isLoading: false
};

export function reducer(state = initialState, action: All): AuthState {
	switch (action.type) {
		case AuthActionTypes.LOGIN: {
			return {
				...state,
				isLoading: true
			};
		}
		case AuthActionTypes.SIGNUP: {
			return {
				...state,
				isLoading: true
			};
		}
		case AuthActionTypes.LOGIN_SUCCESS: {
			return {
				...state,
				isAuthenticated: true,
				user: {
					token: action.token,
					email: action.email
				},
				userInformation: action.tokenUser.user,
				errorMessage: null,
				isLoading: false
			};
		}
		case AuthActionTypes.LOGIN_FAILURE: {
			const errorMessage = action.payload.error.error.userMessage || 'Incorrect email and/or password.';
			return {
				...state,
				errorMessage: errorMessage,
				isLoading: false,
				isAuthenticated: false
			};
		}
		case AuthActionTypes.SIGNUP_SUCCESS: {
			return {
				...state,
				isAuthenticated: true,
				user: {
					token: action.payload.token,
					email: action.payload.email
				},
				errorMessage: null,
				isLoading: false
			};
		}
		case AuthActionTypes.SIGNUP_FAILURE: {
			return {
				...state,
				errorMessage: 'That email is already in use.',
				isLoading: false
			};
		}
		case AuthActionTypes.LOGOUT: {
			return initialState;
		}
		default: {
			return state;
		}
	}
}
