import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { TicketTimeTracking } from 'src/app/_models/ticket-time-tracking';
import { TrackingTimeSheetViewModel } from 'src/app/_view-models/tracking-timesheet-view-model';
import * as moment from 'moment';
import { TrackingTaskItem } from 'src/app/_view-models/tracking-view-model';

@Component({
	selector: 'tim-trackings-time-sheet',
	templateUrl: './trackings-time-sheet.component.html',
	styles: []
})
export class TrackingsTimeSheetComponent implements OnInit, OnChanges {

	@Input()
	public trackings: TicketTimeTracking[];

	@Input()
	public loading: boolean;

	public trackingViewModel: TrackingTimeSheetViewModel[] = [];

	constructor() { }

	public ngOnInit() { }

	public ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
		const trackingsChanges = changes['trackings'];
		if (trackingsChanges && trackingsChanges.currentValue) {
			this.buildViewModel(trackingsChanges.currentValue as TicketTimeTracking[]);
		}
	}

	private buildViewModel(trackings: TicketTimeTracking[]): void {
		if (!trackings || !trackings.length) {
			return;
		}

		for (let i = 0; i < trackings.length; i++) {
			const current = trackings[i];
			const date = moment(current.TrackingStart).startOf('day');
			const index = this.trackingViewModel.findIndex(x => x.dateUnix === date.unix());

			let currentItem: TrackingTimeSheetViewModel = null;
			if (index === -1) {
				currentItem = new TrackingTimeSheetViewModel(date.unix(), date.toDate());
				this.trackingViewModel.push(currentItem);
			} else {
				currentItem = this.trackingViewModel[index];
			}

			let currentTask: TrackingTaskItem = null;
			const taskIndex = currentItem.tasks.findIndex(x => x.task.TicketTaskId === current.TicketTaskId);
			if (taskIndex === -1) {
				currentTask = new TrackingTaskItem();
				currentTask.task = current.Task;
				currentTask.ticket = current.Ticket;
				currentItem.tasks.push(currentTask);
			} else {
				currentTask = currentItem.tasks[taskIndex];
			}

			currentTask.trackings.push(current);
		}
	}
}
