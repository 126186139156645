import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'tim-unsaved-changes-dialog',
	templateUrl: './unsaved-changes-dialog.component.html',
	styles: []
})
export class UnsavedChangesDialogComponent implements OnInit {
	public question: string;

	public show: boolean;

	private resolve: Function;
	private reject: Function;
	private dialogResult: Promise<boolean>;

	constructor() {
		this.dialogResult = new Promise<boolean>((resolve, reject) => {
			this.resolve = resolve;
			this.reject = reject;
		});
	}

	public ngOnInit(): void { }

	public get result(): Promise<boolean> {
		return this.dialogResult;
	}

	public handleConfirm(): void {
		this.resolve();
	}

	public handleCancel(): void {
		this.reject();
		this.show = false;
	}
}
