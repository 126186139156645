import { Component, OnInit, ViewChild, Inject, ViewContainerRef } from '@angular/core';
import { BaseEditorComponent } from '../base-editor/base-editor.component';
import { UnsavedChangesService } from 'src/app/_services/unsaved-changes.service';

@Component({
	selector: 'tim-base-form-container',
	templateUrl: './base-form-container.component.html',
	styles: []
})
export class BaseFormContainerComponent implements OnInit {

	@ViewChild('timeditor', { static: true })
	public editor: BaseEditorComponent<any>;

	constructor(
		private _unsavedChangesService: UnsavedChangesService,
		private _viewContainerRef: ViewContainerRef) { }

	public ngOnInit(): void {
		this._unsavedChangesService.setViewContainer(this._viewContainerRef);
	}

	public get isDirty(): boolean {
		return this.editor.isDirty;
	}
}
