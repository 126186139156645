import { BaseService } from "./base.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { User } from "../_models/user";
import { HttpClient } from "@angular/common/http";
import { PagingModel } from "../_models/paging-model";

@Injectable()
export class UserService extends BaseService {

    constructor(
        private httpClient: HttpClient
    ) {
        super('users');
    }

    public getById(userId: number): Observable<User> {
        const url = this.getEndpoint(`${userId}`);
        return this.httpClient.get<User>(url);
    }

    public getAllUsers(): Observable<User[]> {
        const url = this.getEndpoint('');
        return this.httpClient.get<User[]>(url);
    }

    public addUser(user: User): Observable<User> {
        const url = this.getEndpoint('');
        return this.httpClient.post<User>(url, JSON.stringify(user));
    }

    public updateUser(user: User): Observable<User> {
        const url = this.getEndpoint(`${user.UserId}`);
        return this.httpClient.put<User>(url, JSON.stringify(user));
    }

    public deleteUser(user: User): Observable<User> {
        const url = this.getEndpoint(`${user.UserId}`);
        return this.httpClient.delete<User>(url);
    }

    public getAllUsersPaged(pageNumber: number = 1): Observable<PagingModel<User>> {
        const params = ['page', pageNumber];
        const url = this.getEndpoint('paged', params);
        return this.httpClient.get<PagingModel<User>>(url);
    }

    public searchUser(searchTerm: string): Observable<User[]> {
        const params = ['q', searchTerm];
        const url = this.getEndpoint('search', params);
        return this.httpClient.get<User[]>(url);
    }
}