import { BaseModel } from "./base-model";
import { TicketTask } from './ticket-task';
import { Ticket } from './ticket';
import { User } from './user';
import { ChargeType } from './charge-type';


export class TicketTimeTracking extends BaseModel {
    TicketsTimeTrackingId: number;
    ClientId: number;
    TicketId: number;
    TicketTaskId: number;
    UserId: number;
    ChargeTypeId: number;
    Description: string;
    TrackingStart: Date;
    TrackingEnd: Date;
    IsVisible: boolean;
    TrackedTime: number;
    RevisedTrackedTime: number;

    Task: TicketTask;
    Ticket: Ticket;
    User: User;
    ChargeType: ChargeType;
}