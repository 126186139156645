import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Ticket } from 'src/app/_models/ticket';
import { TicketService } from 'src/app/_services/ticket.service';
import { debounceTime, tap, switchMap, distinctUntilChanged, catchError } from 'rxjs/operators';

@Component({
	selector: 'tim-ticket-search-dialog',
	templateUrl: './ticket-search-dialog.component.html',
	styles: []
})
export class TicketSearchDialogComponent implements OnInit {
	public loading: boolean;
	public validateForm: FormGroup;
	public searchTerm: Subject<string> = new Subject<string>();
	public searchResults: Observable<Ticket[]>;

	@Output()
	public onSearchCompleted: EventEmitter<number | null> = new EventEmitter<number | null>();

	@Input()
	public show: boolean;

	@Input()
	public disabled: boolean;

	constructor(
		private ticketService: TicketService,
		private fb: FormBuilder
	) {
		this.validateForm = this.fb.group({
			TicketId: [null, Validators.required]
		});
	}

	public ngOnInit(): void {
		this.searchResults = this.searchTerm.pipe(
			debounceTime(500),
			distinctUntilChanged(),
			tap(() => this.loading = true),
			switchMap(term => !term ? of([]) : this.ticketService.searchTicket(term)),
			catchError(error => of([])),
			tap(() => this.loading = false)
		);
	}

	public search(searchText: string) {
		this.searchTerm.next(searchText);
	}

	public handleOkay(): void {
		this.submitForm();
	}

	public handleCancel(): void {
		this.onSearchCompleted.next(null);
	}

	public submitForm(): void {
		const ticketId = this.validateForm.value.TicketId as number;
		if (ticketId) {
			this.onSearchCompleted.next(ticketId);
		}
	}
}
