import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { ChargeType } from '../_models/charge-type';

@Injectable()
export class ChargeTypeService extends BaseService {

    constructor(private http: HttpClient) {
        super('chargetypes');
    }

    public getChargeTypes(): Observable<Array<ChargeType>> {
        const url = this.getEndpoint('');
        return this.http.get<Array<ChargeType>>(url)
    }

    public addChargeType(customer: ChargeType): Observable<ChargeType> {
        const url = this.getEndpoint('');
        return this.http.post<ChargeType>(url, JSON.stringify(customer));
    }

    public getChargeType(customerId: number): Observable<ChargeType> {
        const url = this.getEndpoint(`${customerId}`);
        return this.http.get<ChargeType>(url);
    }

    public searchChargeType(searchTerm: string): Observable<ChargeType[]> {
        // const params = ['q', searchTerm, 'field', 'Label'];
        // const url = this.getEndpoint('search', params);
        const url = this.getSearchEndpoint('', searchTerm, 'Label');
        return this.http.get<ChargeType[]>(url);
    }

    public updateChargeType(customer: ChargeType): Observable<ChargeType> {
        const url = this.getEndpoint(`${customer.ChargeTypeId}`);
        return this.http.put<ChargeType>(url, JSON.stringify(customer));
    }

    public deleteChargeType(customer: ChargeType): Observable<ChargeType> {
        const url = this.getEndpoint(`${customer.ChargeTypeId}`);
        return this.http.delete<ChargeType>(url);
    }
}
