import { All, UserQueryActionTypes } from '../actions/user-query.actions';
import { UserQuery } from 'src/app/_models/user-query';

export interface UserQueryState {
    usersQueries: UserQuery[];
    usersQueriesLoading: boolean;
}

export const initialState: UserQueryState = {
    usersQueries: [],
    usersQueriesLoading: false
};

export function reducer(state = initialState, action: All) {
    switch (action.type) {
        case UserQueryActionTypes.GET_USERSQUERIES: {
            return {
                ...state,
                usersQueriesLoading: true
            };
        }
        case UserQueryActionTypes.GET_USERSQUERIES_SUCCESS: {
            return {
                ...state,
                usersQueries: action.usersQueries,
                usersQueriesLoading: false
            };
        }
        case UserQueryActionTypes.GET_USERSQUERIES_FAILURE: {
            return {
                ...state,
                usersQueriesLoading: false
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};