import { Component, OnInit, forwardRef, Input, ViewChild, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import * as moment from 'moment';

const CUSTOM_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => AdvancedDateTimePickerComponent),
	multi: true,
};

@Component({
	selector: 'tim-advanced-date-time-picker',
	templateUrl: './advanced-date-time-picker.component.html',
	styles: [],
	providers: [CUSTOM_VALUE_ACCESSOR],
})
export class AdvancedDateTimePickerComponent implements OnInit, ControlValueAccessor {
	public show: boolean;
	public selectedDate: string;
	public selectedTime: string;

	private _selectedDateString: string;

	@Input()
	public name: string;

	@Input()
	public label: string;

	public disabled: boolean;
	private onChange: Function;
	private onTouched: Function;

	constructor() {
		this.onChange = (_: any) => { };
		this.onTouched = () => { };
		this.disabled = false;
	}

	public get selectedDateString(): string {
		return this._selectedDateString;
	}
	public set selectedDateString(value: string) {
		const changedDate = moment(value, 'L LT');
		this.selectedDate = changedDate.format('DD.MM.YYYY');
		this.selectedTime = changedDate.format('HH:mm');

		if (value !== this._selectedDateString) {
			this._selectedDateString = value;
			this.onChange(changedDate.toISOString());
			this.onTouched();
		}
	}

	public ngOnInit(): void { }

	public handleShowModal(): void {
		this.show = true;
	}

	public handleCancel(): void {
		this.show = false;
	}

	public handleOkay(): void {
		const selectedTime = moment(this.selectedTime, 'HH:mm');
		const selectedDate = moment(this.selectedDate, 'DD.MM.YYYY');
		const selectedDateTime = selectedDate.add({ hours: selectedTime.hours(), minutes: selectedTime.minutes() });
		// console.log('dateTime', selectedDateTime.format('L LT'));

		this.selectedDateString = selectedDateTime.format('L LT');
		this.onChange(selectedDateTime.toISOString());
		this.onTouched();
		this.show = false;
	}

	public submitForm(): void {
		this.handleOkay();
	}


	public writeValue(obj: string | null): void {
		if (obj) {
			this.selectedDateString = moment(obj, moment.ISO_8601).format('L LT');
			this.selectedDate = moment(obj, moment.ISO_8601).format('DD.MM.YYYY');
			this.selectedTime = moment(obj, moment.ISO_8601).format('HH:mm');
		}
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
