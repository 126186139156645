import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TicketTimeTracking } from 'src/app/_models/ticket-time-tracking';
import { TicketTask } from 'src/app/_models/ticket-task';

@Component({
	selector: 'tim-tracking-user-time-sheet-details',
	templateUrl: './tracking-user-time-sheet-details.component.html',
	styles: []
})
export class TrackingUserTimeSheetDetailsComponent implements OnInit {

	@Input()
	public tracking: TicketTimeTracking

	@Output()
	public onTrackingStart: EventEmitter<TicketTask> = new EventEmitter<TicketTask>();

	constructor() { }

	public ngOnInit(): void {
	}

	public handleTrackingStart(task: TicketTask): void {
		this.onTrackingStart.emit(task);
	}
}
