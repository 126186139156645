import { Component, OnInit, ViewChild, OnDestroy, Input, forwardRef } from '@angular/core';
import { ChargeTypeService } from 'src/app/_services/charge-type.service';
import { Observable, Subscription } from 'rxjs';
import { ChargeType } from 'src/app/_models/charge-type';
import { ControlValueAccessor, NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ChargeTypeSelectorComponent),
	multi: true,
};

@Component({
	selector: 'tim-charge-type-selector',
	providers : [CUSTOM_VALUE_ACCESSOR],
	templateUrl: './charge-type-selector.component.html',
	styles: []
})
export class ChargeTypeSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
	public chargeTypes: Observable<ChargeType[]>;
	public chargeTypeId: number;

	private formSubscription: Subscription;

	private disabled: boolean;
	private onChange: Function;
	private onTouched: Function;

	@ViewChild('form', {static: true})
	private ngForm: NgForm;

	@Input()
	public name: string;

	@Input()
	public title: string;

	constructor(
		private chargeTypeService: ChargeTypeService
	) {
		this.onChange = (_: any) => { };
		this.onTouched = () => { };
		this.disabled = false;
	}

	public ngOnInit(): void {
		this.chargeTypes = this.chargeTypeService.getChargeTypes();
		this.formSubscription = this.ngForm.form.valueChanges.subscribe(x => {
			const chargeTypeId = x[this.name] as number;
			this.onChange(chargeTypeId);
			this.onTouched();
		});
	}

	public ngOnDestroy(): void {
		this.formSubscription.unsubscribe();
	}

	public writeValue(obj: number): void {
		this.chargeTypeId = obj;
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
