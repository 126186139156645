import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
	selector: 'tim-base-editor',
	templateUrl: './base-editor.component.html',
	styles: []
})
export class BaseEditorComponent<T> implements OnInit, OnChanges, OnDestroy {
	public form: FormGroup;
	public originalObject: T;

	@Input()
	public value: T;

	@Output()
	public onDirty: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	public onSave: EventEmitter<T> = new EventEmitter<T>();

	@Output()
	public onDelete: EventEmitter<T> = new EventEmitter<T>();


	public get isDirty(): boolean {
		return this._isDirty;
	}

	public set isDirty(dirty: boolean) {
		if (this._isDirty !== dirty) {
			this.onDirty.emit(dirty);
			this._isDirty = dirty;
		}
	}

	private subscription: Subscription;
	private _isDirty: boolean;

	constructor() { }

	public ngOnInit(): void { }

	public ngOnChanges(changes: SimpleChanges): void {
		const valueChanges = changes['value'];
		if (valueChanges && valueChanges.currentValue) {
			this.listenForChanges(this.value, this.form);
		}
	}

	private listenForChanges(initial: T, form: FormGroup): void {
		this.originalObject = Object.assign({}, initial);
		this.form = form;
		this.subscription = this.form.valueChanges.subscribe(v => {
			this.validate(v as T);
		});
	}

	private validate(changes: T): void {
		if (!this.originalObject) {
			this.isDirty = true;
			return;
		}

		for (let prop in this.form.controls) {
			const x = changes.hasOwnProperty(prop);
			if (!x) {
				continue;
			}

			if (!this.originalObject[prop] && !changes[prop]) {
				continue;
			}

			const date = moment(changes[prop], moment.ISO_8601);
			if (date.isValid()) {
				if (!this.originalObject[prop] || !date.isSame(moment(this.originalObject[prop]), 'day')) {
					// Datums-Änderung
					this.isDirty = true;
					break;
				}
			} else if (this.originalObject[prop] !== changes[prop]) {
				// Einfache Wert-Änderung
				this.isDirty = true;
				break;
			} else if (!this.originalObject[prop] && changes[prop]) {
				// Neuanlage eines Objekts
				this.isDirty = true;
				break;
			}

			this.isDirty = false;
		}
	}

	public submitForm(value: T) {
		const savedTicket = Object.assign({}, this.value, this.form.value) as T;
		this.onSave.next(savedTicket);
		this.originalObject = Object.assign({}, savedTicket) as T;
		this.isDirty = false;
	}

	public handleDelete(): void {
		this.onDelete.next(this.value);
	}

	public ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
