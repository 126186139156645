export class PagingModel<T> {

    constructor(dataConst: T[] = null) {
        this.data = dataConst;
        this.pageNumber = 0;
        this.pageSize = 0;
        this.totalPages = 0;
        this.totalRows = 0;
    }
    
    public totalRows: number;

    public totalPages: number;

    public pageNumber: number;

    public pageSize: number;

    public data: T[];
}