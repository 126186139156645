import { AppConfig } from "../../environments/environment";
import { Params } from "@angular/router";

export class BaseService {
    protected _serverUrl: string = `${AppConfig.protocol}://${AppConfig.apiUrl}:${AppConfig.apiPort}`;

    constructor(private resourceUrl?: string) {
        if (AppConfig.fullApiUrl) {
            this._serverUrl = AppConfig.fullApiUrl;
        } else if (AppConfig.apiDirectory) {
            this._serverUrl = `${AppConfig.protocol}://${AppConfig.apiUrl}:${AppConfig.apiPort}/${AppConfig.apiDirectory}`;
        }
    }

    protected getEndpoint(endpointUrl: string, parameters: Params | Params[] = null): string {
        let url = `${this._serverUrl}/${endpointUrl}`;
        if (this.resourceUrl) {
            url = `${this._serverUrl}/${this.resourceUrl}/${endpointUrl}`;
        }

        if (parameters && parameters.length) {
            url += '?';
            for (let i = 0; i < parameters.length; i = i + 2) {
                url += `${encodeURI(parameters[i])}=${encodeURI(parameters[i + 1])}`;
            }
        }

        return url;
    }

    protected getParamsEndpoint(endpointUrl: string, parameters: Object): string {
        let url = this.getEndpoint(endpointUrl);
        let index = 0;

        for (const param in parameters) {
            const value = parameters[param];
            if (!value) {
                continue;
            }
            url += index === 0
                ? `?${param}=${value}`
                : `&${param}=${value}`;

            index++;
        }

        return url;
    }

    protected getSearchEndpoint(endpointUrl: string, searchTerm: string, searchField: string): string {
        let url = `${this._serverUrl}/${endpointUrl}`;
        if (this.resourceUrl) {
            url = `${this._serverUrl}/${this.resourceUrl}/${endpointUrl}`;
        }
        if (url.endsWith('/')) {
            url += 'search/'
        } else {
            url += '/search/'
        }

        const parameters = [
            ['q', searchTerm],
            ['field', searchField]
        ];

        if (parameters && parameters.length) {
            url += '?';
            for (let x = 0; x < parameters.length; x++) {
                const param = parameters[x];
                for (let i = 0; i < param.length; i = i + 2) {
                    url += x > 0
                        ? `&${encodeURI(param[i])}=${encodeURI(param[i + 1])}`
                        : `${encodeURI(param[i])}=${encodeURI(param[i + 1])}`;
                }
            }
        }

        return url;
    }
}
