import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CustomerService } from 'src/app/_services/customer.service';
import { Subject, Observable, of } from 'rxjs';
import { Customer } from 'src/app/_models/customer';
import { debounceTime, distinctUntilChanged, switchMap, catchError, tap } from 'rxjs/operators';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
	selector: 'tim-customer-search-dialog',
	templateUrl: './customer-search-dialog.component.html',
	styles: []
})
export class CustomerSearchDialogComponent implements OnInit {
	public loading: boolean;
	public validateForm: FormGroup;
	public searchTerm: Subject<string> = new Subject<string>();
	public searchResults: Observable<Customer[]>;

	@Output()
	public onSearchCompleted: EventEmitter<number | null> = new EventEmitter<number | null>();

	@Input()
	public show: boolean;

	constructor(
		private customerService: CustomerService,
		private fb: FormBuilder
	) {
		this.validateForm = this.fb.group({
			CustomerId: [null, Validators.required]
		});
	}

	public ngOnInit(): void {
		this.searchResults = this.searchTerm.pipe(
			debounceTime(500),
			distinctUntilChanged(),
			tap(() => this.loading = true),
			switchMap(term => !term ? of([]) : this.customerService.searchCustomer(term)),
			catchError(error => of([])),
			tap(() => this.loading = false)
		);
	}

	public search(searchText: string) {
		this.searchTerm.next(searchText);
	}

	public handleOkay(): void {
		this.submitForm();
	}

	public handleCancel(): void {
		this.onSearchCompleted.next(null);
	}

	public submitForm(): void {
		const customerId = this.validateForm.value.CustomerId as number;
		if (customerId) {
			this.onSearchCompleted.next(customerId);
		}
	}
}
