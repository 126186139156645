import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { TicketTimeTracking } from 'src/app/_models/ticket-time-tracking';
import { TicketTask } from 'src/app/_models/ticket-task';
import * as moment from 'moment';
import { interval, Observable } from 'rxjs';
import { takeWhile, map } from 'rxjs/operators';


@Component({
	selector: 'tim-tracking-card',
	templateUrl: './tracking-card.component.html',
	styles: []
})
export class TrackingCardComponent implements OnInit, OnChanges {

	@Input()
	public tracking: TicketTimeTracking

	@Input()
	public title: string;

	@Output()
	public onStartTracking: EventEmitter<TicketTask> = new EventEmitter<TicketTask>();

	@Output()
	public onStopTracking: EventEmitter<TicketTimeTracking> = new EventEmitter<TicketTimeTracking>();

	public trackingTime: Observable<string>;

	public runningTracking: boolean = false;
	public startEnabled: boolean = false;
	public stopEnabled: boolean = false;

	public ngOnInit(): void {
		this.trackingTime = interval(1000).pipe(
			takeWhile(tracking => !this.tracking.TrackingEnd),
			map(tracking => {
				const end = moment();
				const startTime = moment(this.tracking.TrackingStart);
				const duration = moment.duration(end.diff(startTime));
				return moment.utc(duration.asMilliseconds()).format("HH:mm");

				// return duration;
				// return `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
			}));
	}

	public ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
		const trackingChanges = changes['tracking'];
		if (trackingChanges && trackingChanges.currentValue) {
			this.stopEnabled = !this.tracking.TrackingEnd;
			this.startEnabled = !this.stopEnabled;
			this.runningTracking = this.stopEnabled;
		}
	}

	public startTracking(): void {
		this.onStartTracking.next(this.tracking.Task);
	}

	public stopTracking(): void {
		this.onStopTracking.next(this.tracking);
	}

	/*
var x = new moment()
  var y = new moment()
  var duration = moment.duration(x.diff(y))
	*/
}
