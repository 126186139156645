import { createFeatureSelector } from '@ngrx/store';

import * as auth from './reducers/auth.reducers';
import * as tracking from './reducers/ticket-time-tracking.reducers';
import * as userQuery from './reducers/user-query.reducers';

export interface AppState {
	authState: auth.AuthState;
	trackingState: tracking.TicketTimeTrackingState;
	userQueryState: userQuery.UserQueryState;
}

export const reducers = {
	auth: auth.reducer,
	tracking: tracking.reducer,
	userQuery: userQuery.reducer
};

export const selectAuthState = createFeatureSelector<AppState>('auth');
export const selectTrackingState = createFeatureSelector<AppState>('tracking');
export const selectUserQueryState = createFeatureSelector<AppState>('userQuery');