import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TicketStatus } from '../_models/ticket-status';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class TicketStatusService extends BaseService {
    constructor(private httpClient: HttpClient) {
        super('ticketsstatuses');
    }

    public getStatuses(): Observable<TicketStatus[]> {
        const url = this.getEndpoint('');
        return this.httpClient.get<TicketStatus[]>(url);
    }

    public getStatusById(statusId: number): Observable<TicketStatus> {
        const url = this.getParamsEndpoint('', { StatusId: statusId });
        return this.httpClient.get<TicketStatus>(url);
    }
}