import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { User } from 'src/app/_models/user';

@Component({
	selector: 'tim-user-name-display',
	templateUrl: './user-name-display.component.html',
	styles: []
})
export class UserNameDisplayComponent implements OnInit {

	@Input()
	public user: User;

	constructor() { }

	public ngOnInit(): void { }

}
