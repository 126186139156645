import { Directive, EventEmitter, HostListener } from '@angular/core';

@Directive({
	selector: 'button[timConfirmDialogTrigger][type="button"],a[timConfirmDialogTrigger]'
})
export class ConfirmDialogTriggerDirective {
	public onClick: EventEmitter<any> = new EventEmitter<any>();

	constructor() { }

	@HostListener('click')
	public handleClick(): void {
		this.onClick.next();
	}
}
