import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './_services/auth-guard.service';


const routes: Routes = [
	{
		path: '',
		redirectTo: 'tickets',
		pathMatch: 'full'
	}, {
		path: 'tickets',
		canActivate: [AuthGuardService],
		loadChildren: () => import('./tickets/tickets.module').then(mod => mod.TicketsModule)
	}, {
		path: 'customers',
		canActivate: [AuthGuardService],
		loadChildren: () => import('./customers/customers.module').then(mod => mod.CustomersModule)
	}, {
		path: 'tasks',
		canActivate: [AuthGuardService],
		loadChildren: () => import('./tasks/tasks.module').then(mod => mod.TasksModule)
	}, {
		path: 'trackings',
		canActivate: [AuthGuardService],
		loadChildren: () => import('./trackings/trackings.module').then(mod => mod.TrackingsModule)
	}, {
		path: 'planning',
		canActivate: [AuthGuardService],
		loadChildren: () => import('./planning/planning.module').then(mod => mod.PlanningModule)
	}, {
		path: 'statistics',
		canActivate: [AuthGuardService],
		loadChildren: () => import('./statistics/statistics.module').then(mod => mod.StatisticsModule)
	}, {
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
