import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import { UserQueryService } from '../../_services/user-query.service';
import { GetUsersQueriesSuccess, GetUsersQueries, UserQueryActionTypes, GetUsersQueriesFailure } from '../actions/user-query.actions';


@Injectable()
export class UserQueryEffects {

    constructor(
        private actions: Actions,
        private userQueryService: UserQueryService
    ) { }






    @Effect()
    GetUsersQueries: Observable<GetUsersQueriesSuccess | GetUsersQueriesFailure> = this.actions.pipe(
        ofType(UserQueryActionTypes.GET_USERSQUERIES),
        map(x => x as GetUsersQueries),
        switchMap(params => {
            return this.userQueryService.getByUserId(params.userId).pipe(
                map(queries => new GetUsersQueriesSuccess(queries)),
                catchError(error => of(new GetUsersQueriesFailure(error))));
        }));

    @Effect({ dispatch: false })
    GetUsersQueriesSuccess: Observable<GetUsersQueriesSuccess> = this.actions.pipe(
        ofType(UserQueryActionTypes.GET_USERSQUERIES_SUCCESS),
        tap(user => user)
    );

    @Effect({ dispatch: false })
    GetUsersQueriesFailure: Observable<GetUsersQueriesFailure> = this.actions.pipe(
        ofType(UserQueryActionTypes.GET_USERSQUERIES_FAILURE)
    );
}