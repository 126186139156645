import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HelpersModule } from './helpers/helpers.module';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './_store/effects/auth.effects';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor, TokenInterceptor } from './_services/token.interceptor';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { StoreModule } from '@ngrx/store';
import { reducers } from './_store/app.states';
import { AuthService } from './_services/auth.service';
import { AlertService } from './_services/alert.service';
import { AuthGuardService } from './_services/auth-guard.service';
import { CustomerService } from './_services/customer.service';
import { ClarityModule } from "@clr/angular";
import { RouterModule, Router } from '@angular/router';
import { TicketService } from './_services/ticket.service';
import { AngularDateHttpInterceptor } from './_services/date.interceptor';
import { ChargeTypeService } from './_services/charge-type.service';
import { UserService } from './_services/user.service';
import { TicketTaskService } from './_services/ticket-task.service';
import { TicketTimeTrackingService } from './_services/ticket-time-tracking.service';
import { TicketTimeTrackingEffects } from './_store/effects/ticket-time-tracking.effects';
import { UserQueryService } from './_services/user-query.service';
import { UserQueryEffects } from './_store/effects/user-query.effects';
import { FormsDeactivateService } from './_services/forms-deactivate.service';
import { UnsavedChangesService } from './_services/unsaved-changes.service';
import { ConfirmDialogComponent } from './helpers/confirm-dialog/confirm-dialog.component';
import { UnsavedChangesDialogComponent } from './helpers/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { TicketStatusService } from './_services/ticket-status.service';

registerLocaleData(localeDe, 'de');
@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		RouterModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HelpersModule,
		ClarityModule,
		StoreModule.forRoot(reducers, {}),
		EffectsModule.forRoot([AuthEffects, TicketTimeTrackingEffects, UserQueryEffects])
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		}, {
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true
		},
		// {
		// 	provide: HTTP_INTERCEPTORS,
		// 	useClass: AngularDateHttpInterceptor,
		// 	multi: true
		// },
		// { provide: NZ_I18N, useValue: de_DE },
		{ provide: LOCALE_ID, useValue: 'de' },
		AuthService,
		AlertService,
		UserService,
		CustomerService,
		TicketService,
		TicketTaskService,
		TicketTimeTrackingService,
		UserQueryService,
		ChargeTypeService,
		AuthGuardService,
		FormsDeactivateService,
		UnsavedChangesService,
		TicketStatusService
	],
	bootstrap: [AppComponent],
	entryComponents: [UnsavedChangesDialogComponent]
})
export class AppModule {
	constructor(router: Router) {
		// console.warn('routes', router);
	}
}
