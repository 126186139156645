import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'hour'
})
export class HourPipe implements PipeTransform {

	public transform(value: number, ...args: any[]): string {
		if (!value) {
			return '0 (h)';
		}

		const rounded = parseFloat(value.toString()).toFixed(2);
		return `${rounded} (h)`
	}

}
