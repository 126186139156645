import { Injectable, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { UnsavedChangesDialogComponent } from '../helpers/unsaved-changes-dialog/unsaved-changes-dialog.component';

@Injectable()
export class UnsavedChangesService {
	private viewContainerRef: ViewContainerRef;


	constructor(private componentFactoryResolver: ComponentFactoryResolver) {

	}

	public setViewContainer(container: ViewContainerRef): void {
		this.viewContainerRef = container;
	}

	public addDynamicComponent(): Promise<boolean> {
		const factory = this.componentFactoryResolver
			.resolveComponentFactory(UnsavedChangesDialogComponent);
		const component = factory
			.create(this.viewContainerRef.parentInjector);

		component.instance.question = 'Ungespeicherte Änderungen verwerfen?';
		component.instance.show = true;
		this.viewContainerRef.insert(component.hostView);

		return component.instance.result;
	}
}
