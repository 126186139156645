import { TrackingTaskItem } from './tracking-view-model';
import { TicketTimeTracking } from '../_models/ticket-time-tracking';


export class TrackingTimeSheetViewModel {
    constructor(dateUnix: number, date: Date) {
        this.tasks = [];
        this.date = date;
        this.dateUnix = dateUnix;
    }

    public dateUnix: number;
    public date: Date;
    public tasks: TrackingTaskItem[];
}

export class UserTimeSheetViewModel {
    constructor(dateUnix: number, date: Date) {
        this.tasks = [];
        this.date = date;
        this.dateUnix = dateUnix;
    }

    public dateUnix: number;
    public date: Date;
    public tasks: TicketTimeTracking[];
    public totalTime: number;
}