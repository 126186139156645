import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { TicketTask } from 'src/app/_models/ticket-task';
import { TicketTimeTracking } from 'src/app/_models/ticket-time-tracking';

@Component({
	selector: 'tim-tracking-buttons',
	templateUrl: './tracking-buttons.component.html',
	styles: []
})
export class TrackingButtonsComponent implements OnInit, OnChanges {

	@Input()
	public task: TicketTask;

	@Input()
	public currentTracking: TicketTimeTracking;

	@Output()
	public onTrackingStart: EventEmitter<TicketTask> = new EventEmitter<TicketTask>();

	@Output()
	public onTrackingEnd: EventEmitter<TicketTimeTracking> = new EventEmitter<TicketTimeTracking>();

	public stopEnabled: boolean;
	public startEnabled: boolean;

	constructor() {
		this.startEnabled = true;
		this.stopEnabled = false;
	}

	public ngOnInit(): void { }

	public ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
		const currentTrackingChanges = changes['currentTracking'];
		if (currentTrackingChanges && currentTrackingChanges.currentValue) {
			this.startEnabled = this.task.TicketTaskId !== this.currentTracking.TicketTaskId;
			this.stopEnabled = !this.startEnabled;
		} else if (currentTrackingChanges && !currentTrackingChanges.currentValue) {
			this.startEnabled = true;
			this.stopEnabled = false;
		}
	}

	public handleStart(): void {
		this.onTrackingStart.next(this.task);
	}

	public handleStop(): void {
		this.onTrackingEnd.next(this.currentTracking);
	}
}
