export class AuthResponse {
    success: boolean;
    token: string;
    user: AuthResponseUser;
}

export class AuthResponseUser {
    userName: string;
    firstName: string;
    lastName: string;
    userId: number;
    email: string;
    lastLogin: Date;
}

export class AuthRequest {
    userName: string;
    password: string;
    rememberMe: boolean;
}
