import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Ticket } from '../_models/ticket';
import { PagingModel } from '../_models/paging-model';

@Injectable()
export class TicketService extends BaseService {

    constructor(private http: HttpClient) {
        super('tickets');
    }

    public getTickets(): Observable<Array<Ticket>> {
        const url = this.getEndpoint('');
        return this.http.get<Array<Ticket>>(url)
    }

    public getTicketsPaged(pageNumber: number): Observable<PagingModel<Ticket>> {
        const params = ['page', pageNumber];
        const url = this.getEndpoint('paged', params);
        return this.http.get<PagingModel<Ticket>>(url)
    }

    public addTicket(ticket: Ticket): Observable<Ticket> {
        const url = this.getEndpoint('');
        return this.http.post<Ticket>(url, JSON.stringify(ticket));
    }

    public getTicket(ticketId: number): Observable<Ticket> {
        const url = this.getEndpoint(`${ticketId}`);
        return this.http.get<Ticket>(url);
    }

    public searchTicket(term: string): Observable<Ticket[]> {
        const url = this.getSearchEndpoint('', term, 'ReferenceNumber');
        return this.http.get<Ticket[]>(url);
    }

    public getTicketsByCustomerId(customerId: number): Observable<Ticket[]> {
        const url = this.getParamsEndpoint('', { CustomerId: customerId });
        return this.http.get<Ticket[]>(url);
    }

    public getTicketsByCustomerIdPaged(customerId: number, pageNumber: number): Observable<PagingModel<Ticket>> {
        const url = this.getParamsEndpoint('paged', { CustomerId: customerId, page: pageNumber });
        return this.http.get<PagingModel<Ticket>>(url);
    }

    public getByAssignee(userId: number): Observable<Ticket[]> {
        const url = this.getParamsEndpoint('', { AssignedToId: userId });
        return this.http.get<Ticket[]>(url);
    }

    public getByAssigneePaged(userId: number, pageId: number): Observable<PagingModel<Ticket>> {
        const url = this.getParamsEndpoint('paged', { AssignedToId: userId, page: pageId });
        return this.http.get<PagingModel<Ticket>>(url);
    }

    public getFiltered(filterName: string, pageId: number): Observable<PagingModel<Ticket>> {
        const url = this.getParamsEndpoint('filtered', { page: pageId, filter: filterName });
        return this.http.get<PagingModel<Ticket>>(url);
    }

    public updateTicket(ticket: Ticket): Observable<Ticket> {
        const url = this.getEndpoint(`${ticket.TicketId}`);
        return this.http.put<Ticket>(url, JSON.stringify(ticket));
    }

    public deleteTicket(ticket: Ticket): Observable<Ticket> {
        const url = this.getEndpoint(`${ticket.TicketId}`);
        return this.http.delete<Ticket>(url);
    }
}
