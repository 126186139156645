import { CanActivate, Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
        public auth: AuthService,
        public router: Router
    ) { }
    canActivate(): boolean {
        if (!this.auth.getToken()) {
            this.router.navigateByUrl('/auth/login');
            return false;
        }
        return true;
    }
}
