import { HttpClient } from "@angular/common/http";
import { TokenUser, LoginUser } from "../_models/token-user";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";

@Injectable()
export class AuthService extends BaseService {

    constructor(private http: HttpClient) {
        super('authentication');
    }

    getToken(): string {
        return localStorage.getItem('token');
    }

    checkLogin(): Observable<TokenUser> {
        const userData = localStorage.getItem('user');
        if (userData) {
            const user = JSON.parse(userData) as TokenUser;
            return of(user);
        }

        return of(new TokenUser());
    }

    logIn(email: string, clientName: string, password: string, rememberMe?: boolean): Observable<TokenUser> {
        const url = this.getEndpoint('');
        return this.http.post<LoginUser>(url, { userName: email, clientName, password, rememberMe });
    }

    signUp(email: string, password: string): Observable<LoginUser> {
        const url = this.getEndpoint('register');
        return this.http.post<LoginUser>(url, { email, password });
    }

    getStatus(): Observable<LoginUser> {
        const url = this.getEndpoint('status');
        return this.http.get<LoginUser>(url);
    }
}
