import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';
import { User } from 'src/app/_models/user';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';

@Component({
	selector: 'tim-user-search-dialog',
	templateUrl: './user-search-dialog.component.html',
	styles: []
})
export class UserSearchDialogComponent implements OnInit {
	public loading: boolean;
	public validateForm: FormGroup;
	public searchTerm: Subject<string> = new Subject<string>();
	public searchResults: Observable<User[]>;

	@Output()
	public onSearchCompleted: EventEmitter<number | null> = new EventEmitter<number | null>();

	@Input()
	public show: boolean;

	constructor(
		private userService: UserService,
		private fb: FormBuilder
	) {
		this.validateForm = this.fb.group({
			UserId: [null, Validators.required]
		});
	}

	public ngOnInit(): void {
		this.searchResults = this.searchTerm.pipe(
			debounceTime(500),
			distinctUntilChanged(),
			tap(() => this.loading = true),
			switchMap(term => !term ? of([]) : this.userService.searchUser(term)),
			catchError(error => of([])),
			tap(() => this.loading = false)
		);
	}

	public search(searchText: string) {
		this.searchTerm.next(searchText);
	}

	public handleOkay(): void {
		this.submitForm();
	}

	public handleCancel(): void {
		this.onSearchCompleted.next(null);
	}

	public submitForm(): void {
		const userId = this.validateForm.value.UserId as number;
		if (userId) {
			this.onSearchCompleted.next(userId);
		}
	}

}
