import { Component, OnInit, Input } from '@angular/core';
import { TicketTimeTracking } from 'src/app/_models/ticket-time-tracking';
import { UserTimeSheetViewModel } from 'src/app/_view-models/tracking-timesheet-view-model';
import * as moment from 'moment';

@Component({
	selector: 'tim-trackings-user-time-sheet',
	templateUrl: './trackings-user-time-sheet.component.html',
	styles: []
})
export class TrackingsUserTimeSheetComponent implements OnInit {

	@Input()
	public trackings: TicketTimeTracking[];

	@Input()
	public loading: boolean;

	public trackingViewModel: UserTimeSheetViewModel[] = [];

	constructor() { }

	public ngOnInit() { }

	public ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
		const trackingsChanges = changes['trackings'];
		if (trackingsChanges && trackingsChanges.currentValue) {
			this.buildViewModel(trackingsChanges.currentValue as TicketTimeTracking[]);
		}
	}

	private buildViewModel(trackings: TicketTimeTracking[]): void {
		this.trackingViewModel = [];
		if (!trackings || !trackings.length) {
			return;
		}

		for (let i = 0; i < trackings.length; i++) {
			const current = trackings[i];
			const date = moment(current.TrackingStart).startOf('day');
			const index = this.trackingViewModel.findIndex(x => x.dateUnix === date.unix());

			let currentItem: UserTimeSheetViewModel = null;
			if (index === -1) {
				currentItem = new UserTimeSheetViewModel(date.unix(), date.toDate());
				currentItem.totalTime = 0;
				this.trackingViewModel.push(currentItem);
			} else {
				currentItem = this.trackingViewModel[index];
			}

			currentItem.tasks.push(current);
			currentItem.totalTime = parseFloat(currentItem.totalTime.toString()) + parseFloat((current.TrackedTime || 0).toString());
		}
	}

}
