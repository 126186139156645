import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TicketTimeTracking } from 'src/app/_models/ticket-time-tracking';
import { PagingModel } from 'src/app/_models/paging-model';
import { TicketTask } from 'src/app/_models/ticket-task';

@Component({
	selector: 'tim-trackings-table',
	templateUrl: './trackings-table.component.html',
	styles: []
})
export class TrackingsTableComponent implements OnInit {

	@Input()
	public trackings: PagingModel<TicketTimeTracking> = new PagingModel<TicketTimeTracking>([]);

	@Input()
	public loading: boolean;

	@Output()
	public onTrackingStart: EventEmitter<TicketTask> = new EventEmitter<TicketTask>();

	constructor() { }

	public ngOnInit(): void {
	}

	public get sumTrackings(): number {
		if (!this.trackings || !this.trackings.data || !this.trackings.data.length) {
			return 0;
		}

		const trackings = this.trackings.data
			.filter(x => x.TrackedTime);
		if (!trackings || !trackings.length) {
			return 0;
		}

		return this.trackings.data
			.filter(x => x.TrackedTime)
			.map(x => parseFloat(x.TrackedTime.toString()))
			.reduce((prev, curr) => prev + curr);
	}

	public handleTrackingStart(task: TicketTask): void {
		this.onTrackingStart.emit(task);
	}
}
