import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { ClarityModule } from '@clr/angular';
import { RouterModule } from '@angular/router';
import { CustomerSearchDialogComponent } from './customer-search-dialog/customer-search-dialog.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomerSearchFormControlComponent } from './customer-search-form-control/customer-search-form-control.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { AlertComponent } from './alert/alert.component';
import { ChargeTypeSelectorComponent } from './charge-type-selector/charge-type-selector.component';
import { UserSearchDialogComponent } from './user-search-dialog/user-search-dialog.component';
import { UserSearchFormControlComponent } from './user-search-form-control/user-search-form-control.component';
import { TicketListComponent } from './ticket-list/ticket-list.component';
import { UserNameDisplayComponent } from './user-name-display/user-name-display.component';
import { TicketSearchDialogComponent } from './ticket-search-dialog/ticket-search-dialog.component';
import { TicketSearchFormControlComponent } from './ticket-search-form-control/ticket-search-form-control.component';
import { TrackingCardComponent } from './tracking-card/tracking-card.component';
import { TrackingButtonsComponent } from './tracking-buttons/tracking-buttons.component';
import { AdvancedDateTimePickerComponent } from './advanced-date-time-picker/advanced-date-time-picker.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogTriggerDirective } from './confirm-dialog/confirm-dialog-trigger.directive';
import { TrackingsTableComponent } from './trackings-table/trackings-table.component';
import { TrackingsTimeSheetComponent } from './trackings-time-sheet/trackings-time-sheet.component';
import { TrackingsUserTimeSheetComponent } from './trackings-user-time-sheet/trackings-user-time-sheet.component';
import { TrackingUserTimeSheetDetailsComponent } from './tracking-user-time-sheet-details/tracking-user-time-sheet-details.component';
import { HourPipe } from './_pipes/hour.pipe';
import { LoadingComponent } from './loading/loading.component';
import { BaseEditorComponent } from './base-editor/base-editor.component';
import { BaseFormContainerComponent } from './base-form-container/base-form-container.component';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog/unsaved-changes-dialog.component';
import { BaseListComponent } from './base-list/base-list.component';
import { TicketStatusSelectorComponent } from './ticket-status-selector/ticket-status-selector.component';


@NgModule({
	declarations: [AppLayoutComponent, CustomerSearchDialogComponent, CustomerSearchFormControlComponent, DatePickerComponent, AlertComponent, ChargeTypeSelectorComponent, UserSearchDialogComponent, UserSearchFormControlComponent, TicketListComponent, UserNameDisplayComponent, TicketSearchDialogComponent, TicketSearchFormControlComponent, TrackingCardComponent, TrackingButtonsComponent, AdvancedDateTimePickerComponent, ConfirmDialogComponent, ConfirmDialogTriggerDirective, TrackingsTableComponent, TrackingsTimeSheetComponent, TrackingsUserTimeSheetComponent, TrackingUserTimeSheetDetailsComponent, HourPipe, LoadingComponent, BaseEditorComponent, BaseFormContainerComponent, UnsavedChangesDialogComponent, BaseListComponent, TicketStatusSelectorComponent],
	imports: [
		CommonModule,
		ClarityModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
	],
	exports: [
		AlertComponent,
		AppLayoutComponent,
		DatePickerComponent,
		TicketListComponent,
		TrackingCardComponent,
		ConfirmDialogComponent,
		ConfirmDialogTriggerDirective,
		TrackingsTableComponent,
		TrackingButtonsComponent,
		TicketSearchFormControlComponent,
		UserNameDisplayComponent,
		UserSearchDialogComponent,
		AdvancedDateTimePickerComponent,
		UserSearchFormControlComponent,
		ChargeTypeSelectorComponent,
		CustomerSearchDialogComponent,
		CustomerSearchFormControlComponent,
		TrackingsTimeSheetComponent,
		TrackingsUserTimeSheetComponent,
		TicketStatusSelectorComponent,
		LoadingComponent,
		HourPipe
	],
	providers: []
})
export class HelpersModule { }
